<template>
  <div class='ue4' v-html="$t('description.ue')">
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {}
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
<style lang='scss' scoped>

</style>